<script setup lang="ts">
import { fixOverlayScroll } from '@/shared/lib/fixOverlayScroll'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  background: {
    type: String,
    default: 'bg-white',
  },
  footerButtons: {
    type: String as () => 'col' | 'row',
    default: '',
  },
})

const model = defineModel({ type: Boolean, default: false })

watch(
  () => model.value,
  (opened) => {
    fixOverlayScroll(opened)
  },
)
</script>

<template>
  <Transition name="fade-scale">
    <div
      v-if="model"
      class="fixed bottom-0 left-0 right-0 top-0 z-40 flex items-center justify-center overscroll-none bg-black/75"
    >
      <div
        class="max-h-[98svh] inner-modal-wrapper w-344 rounded-16 flex flex-col justify-between p-16"
        :class="props.background"
        v-bind="$attrs"
        @click.stop
      >
        <div v-if="$slots.header" class="mb-12 shrink-0 grow-0">
          <slot name="header" />
        </div>
        <div
          class="shrink grow overflow-x-hidden overflow-y-scroll"
          :class="{
            'pt-12': $slots.header,
            'pb-12': $slots.footer,
          }"
        >
          <slot />
        </div>
        <div
          v-if="$slots.footer"
          class="shrink-0 grow-0 pt-12"
          :class="{
            'flex gap-16': props.footerButtons === 'row',
            'flex flex-col gap-8': props.footerButtons === 'col',
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Transition>
</template>
